import React from "react";
import './about_style.css'
import AboutComText from "./aboutComText";
import AboutComImage from "./aboutComImage";


export default function AboutCom(){
    return (
        <div className="aboutCom">
            <AboutComText/>
            <AboutComImage/>
        </div>
    )
}