
import "./css_file/about_privacy_terms.css"
const Terms = () => {
    return (
        <div>
            <div className="container" style={{paddingTop:"96px"}}>
                <div className="row">
                    <div className="col-lg-12">
                        <div class="content_back">

                            <div class="detail_box_smooth">
                                <h4 class="detail_box_title">Website Terms and Conditions of Use</h4>
                                <br></br>
                                <div class="info_description">

                                    <p>
                                        <div class="content_main">
                                            By using OS Esports ("the site") and its materials ("data" and "functionality"),
                                            you are agreeing to comply with and be bound by the terms, conditions and notices relating to
                                            its access and use. In order to use the functionality of the Development Area, you must also
                                            agree to the others conditions.

                                        </div>
                                    </p>
                                </div>
                                <hr></hr>
                                <h2 class="detail_box_subtitle">Terms</h2>
                                <div class="info_description">

                                    <p>
                                        <div class="content_main">
                                            By accessing the site, you are agreeing to be bound by these web site Terms and Conditions of
                                            Use, all applicable laws and regulations, and agree that you are responsible for compliance
                                            with any applicable local laws. If you do not agree with any of these terms,
                                            you are prohibited from using or accessing the site. The materials contained in the site are
                                            protected by applicable copyright and trade mark law.

                                        </div>
                                    </p>
                                </div>
                                <hr></hr>
                                <h2 class="detail_box_subtitle">Usage License</h2>
                                <div class="info_description">

                                    <p>
                                        <div class="content_main">
                                            You are granted permission to use the site's materials for both personal and commercial use.
                                            This is the grant of a license, not a transfer of title, and under this license you may not
                                            :</div>
                                    </p>
                                    <div class="content_main">
                                        <ul>
                                            <li>use photos, logos, images, trademarks, or other such property not owned by the site without a
                                                separate usage license or right from the respective owner; or
                                            </li>
                                            <li>attempt to decompile, reverse-engineer or otherwise interfere with any software contained on
                                                the site; or</li><li>remove any copyright or other proprietary notations from the materials; or
                                            </li><li>use information on the site for tax purposes.
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        <div class="content_main">This license shall automatically terminate if you violate any of these restrictions and may be
                                            terminated by the site at any time without notice. Upon termination of this license, you must destroy
                                            any downloaded materials in your possession whether in electronic or printed format.</div>
                                    </p>

                                </div><br></br>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;