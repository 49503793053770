import React from 'react';
import {Link} from 'react-router-dom';
import tourney_details from '../../json_files/tournament_details.json';
import './css_file/tourney_details.css';  
// import Fade from 'react-reveal/Fade';
// import RubberBand from 'react-reveal/RubberBand';

function MoreTourney() {
    return(
    <div className="col-lg-3">
        <div className="mn-list">
            {/* <RubberBand delay={200}> */}
                <h2 className="heading_CSS">Read More</h2>
            {/* </RubberBand> */}
            {/* <Fade right big cascade delay={200}> */}
                <ul>
                    {
                        tourney_details && tourney_details.map(
                        (item) =>
                                <li className="read_more_list">
                                    <Link to={'../tourneydetails/' + item.tourney_id}>
                                        <i className="far fa-hand-point-right"></i>  
                                        &emsp;{item.title_name}
                                    </Link>
                                    <br/>
                                </li>
                        )
                    }
                </ul>
            {/* </Fade> */}
        </div>
    </div>
    );

}

export default MoreTourney;