// import Zoom from 'react-reveal/Zoom';

import { Slide } from "react-awesome-reveal";

function ContentBox(props) {
    return (
        <div className="serviceCard">
                <center>
                <Slide direction='up' triggerOnce="true">
                    <img src={props.icon} alt="icon" className="icon_size" />
                    <h4>
                            {props.title}
                    </h4>
                    <p>
                        {props.description}
                    </p>
                </Slide>
                </center>
            </div>
    );
}
export default ContentBox;