import React from 'react';
import { Link } from 'react-router-dom'
import './css_file/footer.css'
// import Zoom from 'react-reveal/Zoom';
// import RubberBand from 'react-reveal/RubberBand';
import contactJSON from '../../json_files/contact.json'

// function signupbtn() {
//     document.getElementById('Sign-up-wrapper').style.display='block';
//   }

// function loginbtn() {
//     document.getElementById('Log-in-wrapper').style.display='block';
//   }

class Footer extends React.Component {
    render() {
        return (
            <div>
                <div className="footer">
                    <div className="container">
                        <div className="row LeftRight_Margin ">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget" style={{ overflow: 'hidden' }}>
                                    <h3 className="title">
                                        {/* <Zoom top cascade> */}
                                            Get in Touch
                                        {/* </Zoom> */}
                                    </h3>
                                    {
                                        contactJSON && contactJSON.map((val) =>
                                            // <Zoom right cascade key={val.d_title} >
                                                <div className="contact-info" key={val.mail}>
                                                    <p><i className="fa fa-map-marker"></i>
                                                        &emsp;<span className="footerFontSize"> {val.address}</span>
                                                    </p>
                                                    <p><i className="fa fa-envelope"></i>
                                                        <a href={val.toMail} style={{ color: 'white' }} className="footerFontSize">
                                                            &emsp;{val.mail}
                                                        </a>
                                                    </p>
                                                    <p><i className="fa fa-phone"></i>
                                                        <a href={val.telPhone} style={{ color: 'white' }} className="footerFontSize">
                                                            &emsp;{val.phone}
                                                        </a>
                                                    </p>
                                                    <div className="social">
                                                        <a href={val.insta} target="_blank" rel="noreferrer">
                                                            <i title={val.i_title} className={val.i_icon}></i>
                                                        </a>
                                                        <a href={val.youtube} target="_blank" rel="noreferrer">
                                                            <i title={val.y_title} className={val.y_icon}></i>
                                                        </a>
                                                        <a href={val.discord} target="_blank" rel="noreferrer">
                                                            <i title={val.d_title} className={val.d_icon}></i>
                                                        </a>
                                                        {/* <a href={val.rooter} target="_blank" rel="noreferrer">
                                                            <i title={val.r_title} className={val.r_icon}></i>
                                                        </a> */}
                                                        <a href={val.facebook} target="_blank" rel="noreferrer" >
                                                            <i title={val.f_title} className={val.f_icon}></i>
                                                        </a>
                                                        <a href={val.twitter} target="_blank" rel="noreferrer" >
                                                            <i title={val.t_title} className={val.t_icon}></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            // </Zoom>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget" style={{ overflow: 'hidden' }}>
                                    <h3 className="title">
                                            Useful Links
                                    </h3>
                                        <ul>
                                            <li className="footerFontSize"><Link to="#" aa="../eventmanagement" >Event Menagement</Link></li>
                                            <li className="footerFontSize"><Link to="#" aa="../production" >Production</Link></li>
                                            <li className="footerFontSize"><Link to="#" aa="../influencer" >Influencer Marketing</Link></li>
                                            <li className="footerFontSize"><Link to="#" aa="../esports" >Esports</Link></li>
                                            {/* <li className="footerFontSize"><Link to="/" >OS Line-Up</Link></li> */}
                                            {/* <li><Link onClick={signupbtn} >Register</Link></li>
                                        <li><Link onClick={loginbtn} >Log-in</Link></li> */}
                                        </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget" style={{ overflow: 'hidden' }}>
                                    <h3 className="title">
                                            Quick Links
                                    </h3>
                                        <ul>
                                            <li className="footerFontSize"><Link to="#" aa="/tourneylist" >Tournaments</Link></li>
                                            <li className="footerFontSize"><Link to="#" aa="/oscreatives" >OS Creatives</Link></li>
                                            {/* <li className="footerFontSize"><Link to="t2Scrims" >OS Creatives</Link></li>
                                            <li className="footerFontSize"><Link to="t3Scrims" >Tier-3 Scrims</Link></li> */}
                                        </ul>
                                    {/* </Zoom> */}
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget">
                                    <h3 className="title">
                                            About Us
                                    </h3>
                                    <div className="newsletter">
                                        <div>
                                            <p className="footerFontSize">
                                                Omega Slayers is a growing Digital Marketing Community.
                                            </p>
                                        </div>
                                        <form>
                                            <input className="form-control" type="email" placeholder=" Your email here" />
                                            <button className="btn">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-menu">
                    {/* <Zoom right cascade> */}
                        <div className="container">
                            <div className="f-menu">
                                <Link to="#" aa="/terms"><span className="footerFontSize">Terms of use</span></Link>
                                <Link to="#" aa="/privacy"><span className="footerFontSize">Privacy policy</span></Link>
                                <Link to="#" aa=""><span className="footerFontSize">Cookies</span></Link>
                                <Link to="#" aa="/contact"><span className="footerFontSize">Contact us</span></Link>
                            </div>
                        </div>
                    {/* </Zoom> */}
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 copyright">
                                {/* <RubberBand> */}
                                    <p className="footerFontSize">Copyright &copy; Omega Slayers. All Rights Reserved</p>
                                {/* </RubberBand> */}
                            </div>
                            <div className="col-md-6 template-by">
                                <Link to="/" >
                                    {/* <RubberBand> */}
                                        <p className="footerFontSize">Omega Slayers Network</p>
                                    {/* </RubberBand> */}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
