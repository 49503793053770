import React from 'react';
import './about_style.css';
import { Fade, Slide } from "react-awesome-reveal";


export default function AboutComText(){
    return (
        <div className='textComCard'>
            <div className='text-com-card'>
                <Slide triggerOnce="true">
                    <h1>
                        India's Best Digital Marketing Agency...
                    </h1>
                </Slide>
                <br/>
                <div>
                    <Fade direction='up' triggerOnce="true" cascade delay={0.01} damping={0.01}>
                        <h3>
                            Who are we?
                        </h3>
                        <p>
                            At Omega Slayers, we are more than just a company, your partners in success. 
                            Based the vibrant city of Hyderabad, specialize diverse range services.<br /><br />
                            What sets us apart is our unwavering commitment to treating each project as if it were own. <br /><br />
                            When you choose Omega Slayers, you're not just hiring a service provider, gaining dedicated 
                            team that takes pride in delivering results exceed expectations.
                        </p>
                    </Fade>
                </div>
            </div>

        </div>
    )
}