import React from 'react'
import Wave from 'react-wavify'

export default function Wave2(){
    return (
        <Wave fill='#111111'
              paused={false}
              style={{ display: 'flex' , 
                      backgroundImage:"linear-gradient(#f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #f7f7ff, #111111)"
              }}
              options={{
                height: 50,
                amplitude: 70,
                speed: 0.15,
                points: 4
              }}
        />
    )
}
