import React from "react";
import './comStats.css'
import comstats from '../../json_files/comStats.json';
import { Fade, Slide } from "react-awesome-reveal";

export default function ComStats(props) {
    return (
        <div className="comStatsCss">
            <h1 className="comHeading">
                <Slide direction="down" triggerOnce="true">
                    {comstats.heading}  
                </Slide>
            </h1>
            <span className="comSummary">
                <Fade direction="up" triggerOnce="true">
                    {comstats.summary}
                </Fade>
            </span>
            <br />
            <div className="comStatsCardContainer">
                {
                    comstats && comstats.comstats.map(
                        (stat) => {
                            return (
                                <div className="comStatsCard" key={stat.tagLine}>
                                    <img src={stat.imageLink} alt="employees" className="comImg" />
                                    <br />
                                    <br />
                                    <h1>{stat.stats}</h1>
                                    <h5>{stat.tagLine}</h5>
                                </div>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
}