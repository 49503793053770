// import Zoom from 'react-reveal/Zoom';

function CardsTitle(props) {
    return (
            <div className="col-lg-12">
                <center>
                    <h3 className="service_title_subheading">
                            {props.subHeading}
                    </h3>
                    <h1 className="service_title">
                        {props.heading}
                    </h1>
                </center>
            </div>
    );
}
export default CardsTitle;