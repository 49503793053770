import "./css_file/Contact.css"
import Emailing from "./emailing.js";
import Address from "./address.js";

function Contact() {
    return (
            <div className="contact" style={{paddingTop:"10rem"}}>
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-md-8">
                            <h2>
                                Contact us
                            </h2>
                            <Emailing />
                        </div>
                        <div className="col-md-4 pt-2">
                            <Address />
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Contact;