import React from "react";
import './serviceComponent.css';
import { Fade, Slide } from "react-awesome-reveal";


export default function ServiceComp(props){
    return (
        <div className={props.containerClass} style={{backgroundImage:props.backgroundGradient}}>
                <div className="serviceLeftContainer">
                    <h3 className="serviceTitle" style={{color:props.titleColor}}>
                        <Slide direction="up" triggerOnce="true"> 
                            {props.title}
                        </Slide>
                    </h3>
                        <Fade direction="up" triggerOnce="true">
                            <p className="serviceDescription ">
                                    {props.description1}
                            </p>
                        </Fade>
                        <Fade direction="up" triggerOnce="true">
                            <p className="serviceDescription">
                                {props.description2} 
                            </p>
                        </Fade>
                </div>
                <div className="serviceRightContainer">
                    <Fade direction={props.direction} triggerOnce="true">
                        <img src={props.image} alt="event png" className="serViceImg" />
                    </Fade>
                </div>
            </div>
    )
}