import { BrowserRouter, Routes, Route } from "react-router-dom";
        
import './css/App.css';
import './css/style.css';
import './css/bootstrap.min.css';

import './lib/slick/slick.js';
import './js/main.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//component
import Home from './components/Home/home.js';
// import Topbar from "./components/Navbar/Topbar.js";
import About from './components/topbar/About';
import Privacy from './components/topbar/Privacy';
import Contact from './components/topbar/Contact';
import Terms from './components/topbar/Terms';
// import Navbar from "./components/Navbar/navbar.js";
import Brand from "./components/Navbar/brand.js";
import TourneyList from "./components/tournaments/tourney_list.js";
import DetailsPage from "./components/tournaments/detailpage.js";
import Veteran from "./components/scrims/veteran.js";
import T2scrims from "./components/scrims/tier2.js";
import T3scrims from "./components/scrims/tier3.js";
import Creatives from "./components/otherpages/creatives.js";
import Footer from './components/Footer/footer.js';
// import VisitorCount from "./components/visitorCounter.js";

function App() {
  return (
    <div className="mainDivInApp">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Brand/>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path= "/tourneylist" element={<TourneyList />}/>
          <Route exact path= "/tourneydetails/:id" element={<DetailsPage />} />
          <Route exact path= "/vScrims" element={<Veteran />} />
          <Route exact path= "/t2Scrims" element={<T2scrims />} />
          <Route exact path= "/t3Scrims" element={<T3scrims />} />
          <Route exact path= "/megawar" element={<Contact />} />
          <Route exact path= "/oscreatives" element={<Creatives />} />
        </Routes>
        {/* <VisitorCount/> */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}


export default App;
