import React from 'react';
import { Link } from 'react-router-dom';
import './css_file/navBar.css'
// import Zoom from 'react-reveal/Zoom';
// import Bounce from 'react-reveal/Bounce';
import contactJSON from '../../json_files/contact.json'
// function signupbtn() {
//     document.getElementById('Sign-up-wrapper').style.display='block';
//   }

// function loginbtn() {
//     document.getElementById('Log-in-wrapper').style.display='block';
//   }  


class Navbar extends React.Component {
    render() {
        return (
            <div className="nav-bar">
                <div className="nav_LeftRight_Margin">
                    <nav className="navbar navbar-expand-md navbar-dark">
                        <Link to="/" className="navbar-brand" style={{color: "black",fontWeight: "400",fontSize: "21px"}}>Omega Slayers</Link>
                        <button type="button" className="navbar-toggler menu_btn" data-toggle="collapse" data-target="#navbarCollapse">
                            {/* <span className="navbar-toggler-icon"></span> */}
                            <i className="fas fa-bars" style={{color: "black",fontSize: "xx-large"}}></i>
                        </button>

                        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                            <div className="navbar-nav mr-auto">
                                <Link to="/" className="nav-item nav-link" >
                                    {/* <Zoom top cascade delay={200}> */}
                                        <span><i className="fas fa-home"></i></span>
                                        <span className="navbarfontColor"> home</span>
                                    {/* </Zoom> */}
                                </Link>
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown"><span className="navbarfontColor">Our Services</span></a>
                                    <div className="dropdown-menu">
                                        <Link to="#" aa="/eventmanagement" className="dropdown-item">Event Management</Link>
                                        <Link to="#" aa="/production" className="dropdown-item">Production</Link>
                                        <Link to="#" aa="/influencer" className="dropdown-item">Influencer Marketing</Link>
                                        {/* <Link to="/musicandvideo" className="dropdown-item">Music & Video Shoot</Link> */}
                                    </div>
                                </div>
                                {/* <Link to="/eventmanagement" className="nav-item nav-link">
                                        <span className="navbarfontColor" >Event Menagement</span>
                                </Link>
                                <Link to="/production" className="nav-item nav-link">
                                        <span className="navbarfontColor" >Production</span>
                                </Link> */}
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown"><span className="navbarfontColor">Esports</span></a>
                                    <div className="dropdown-menu">
                                        <Link to="#" aa="/tourneylist" className="dropdown-item">Tournaments</Link>
                                        <Link to="#" aa="/oscreatives" className="dropdown-item">OS Creatives</Link>
                                        <Link to="#" aa="/t3Scrims" className="dropdown-item">Tier-3 Scrims</Link>
                                    </div>
                                </div>
                                <div className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown"><span className="navbarfontColor">About</span></a>
                                    <div className="dropdown-menu">
                                        <Link to="#" aa="/about" className="dropdown-item">About us</Link>
                                        <Link to="#" aa="/contact" className="dropdown-item">Contact us</Link>
                                        <Link to="#" aa="/privacy" className="dropdown-item">Privacy</Link>
                                        <Link to="#" aa="/terms" className="dropdown-item">Terms</Link>
                                    </div>
                                </div>
                                {/* <Link to="/influencer" className="nav-item nav-link">
                                        <span className="navbarfontColor" title='coming soon'>Influencer Marketing</span>
                                </Link> */}
                                {/* <Link to="/oslineups" className="nav-item nav-link">
                                    <Zoom top cascade delay={200}>
                                        <span className="navbarfontColor" title='coming soon'>OS Lineup</span>
                                    </Zoom>
                                </Link> */}
                                {/* <Link to="/oscreatives" className="nav-item nav-link">
                                        <span className="navbarfontColor" title='coming soon'>OS Creatives</span>
                                </Link> */}
                            </div>
                            {
                                contactJSON && contactJSON.map((val) =>
                                    <div key={val.d_title} className="social ml-auto" style={{ overflow: 'hidden' }}>
                                        {/* 
                                            <Link onClick={signupbtn}><i class="fas fa-users"></i></Link>
                                            <Link onClick={loginbtn}><i class="fas fa-sign-in-alt"></i></Link>
                                        */}
                                        {/* <Bounce right cascade delay={200}> */}
                                            <a href={val.insta} target="_blank" rel="noreferrer">
                                                <i title={val.i_title} className={val.i_icon}></i>
                                            </a>
                                            <a href={val.youtube} target="_blank" rel="noreferrer">
                                                <i title={val.y_title} className={val.y_icon}></i>
                                            </a>
                                            <a href={val.discord} target="_blank" rel="noreferrer">
                                                <i title={val.d_title} className={val.d_icon}></i>
                                            </a>
                                            {/* <a href={val.rooter} target="_blank" rel="noreferrer">
                                                <i title={val.r_title} className={val.r_icon}></i>
                                            </a> */}
                                            <a href={val.facebook} target="_blank" rel="noreferrer">
                                                <i title={val.f_title} className={val.f_icon}></i>
                                            </a>
                                            <a href={val.twitter} target="_blank" rel="noreferrer">
                                                <i title={val.t_title} className={val.t_icon}></i>
                                            </a>
                                        {/* </Bounce> */}
                                    </div>
                                )
                            }

                        </div>
                    </nav>
                </div>

            </div>
        );
    }
}

export default Navbar;