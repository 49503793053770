// import SimpleSlider from './slider.js';
// import MoreList from './moreList.js';
// import Categorynews from './categorynews.js';
// import SocialInsights from './socialInsights.js';
// import Partner from './partner.js';
// import Insta from './insta_feed.js';
import Services from './our_services.js';
// import OurTeam from './ourTeam.js';
// import Jarsey from './jarsey.js'
// import EsportsContri from './esportsContri.js';
import AboutCom from '../About/aboutCom.js';
import ComStats from '../ComStats/comStats.js';
import ServiceContainer from '../OSServices/serviceCompContainer.js';
// import CaseStudyContainer from '../caseStudy/caseStudyContainer.js';
import PartnerBrand from '../PartnerBrand/partnerBrand.js';
import Wave2 from '../WaveContainer/wave2.js';
import Contact from '../topbar/Contact.js';
// import Wave from '../WaveContainer/wave.js';

function Home() {
  return (
    <>
      <div style={{height:"80px"}}></div>
      <AboutCom />
      <ComStats/>
      {/* <br/>
      <br/> */}
      <Services />
      <ServiceContainer />
      <PartnerBrand />
      {/* <CaseStudyContainer /> */}
      <Contact />
      <Wave2 />
      {/* <Wave /> */}
      {/* <SocialInsights /> */}
      {/* <SimpleSlider /> */}
      {/* <EsportsContri /> */}
      {/* <Categorynews /> */}
      {/* <MoreList /> */}
      {/* <OurTeam /> */}
      {/* <Partner /> */}
      {/* <Insta /> */}
      {/* <br></br>
      <br></br> */}
      {/* <Jarsey /> */}
      {/* <br /><br /> */}
    </>
  );
}

export default Home;
