import { useState } from "react";
import Axios from "axios";
function Emailing() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [phone, setPhone] = useState("");
    const [query, setQuery] = useState("");
    const Query = async e => {
        e.preventDefault();
        let queryUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfo3h8NMK38IpbH_l9NQrBry-mJGIEdYvOG_SK3oJSIggYaDg/formResponse?';
        let queryStr = 'entry.865625286='+name+'&entry.22393296='+email+'&entry.1949262446='+subject+'&entry.688690082='+phone+'&entry.1247214990='+query;
        Axios.get(queryUrl+queryStr,null,null).then((res) => {
            if (res.data.message != null)
                alert(res.data.message);
            else
                alert('Error in sending query');

        }).catch((err)=>{
            alert("Query submitted successfully");
            // console.log(err);
        });
    };
    
    return (
        <div className="contact-form" >
            <form onSubmit={Query}>
                <div className="form-row">
                    <div className="form-group col-md-5" >
                        <input type="text" className="form-control" placeholder="Name"
                            name="entry.865625286"
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group col-md-7">
                        <input type="email" className="form-control" placeholder="Email"
                            name="entry.22393296"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-8" >
                        <input type="text" className="form-control" placeholder="Subject" style={{ width: '100%' }}
                            name="entry.1949262446"
                            onChange={(event) => {
                                setSubject(event.target.value);
                            }}
                        />
                    </div>
                    <div className="form-group col-md-4" >
                        <input type="tel" id="phone" name="entry.688690082" className="form-control"
                            placeholder="Phone:- 1234567890" pattern="[0-9]{3}[0-9]{4}[0-9]{3}" 
                            required style={{ width: '100%' }}
                            onChange={(event) => {
                                setPhone(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <textarea className="form-control " id="textarea" rows="5" placeholder="Type your Query" style={{ width: '100%' }}
                        name="entry.1247214990"
                        onChange={(event) => {
                            setQuery(event.target.value);
                        }}
                    ></textarea>
                </div>
                <div>
                    <button className="btn form-control" style={{ width: '100%' }} type="submit">Send Message</button>
                </div>
            </form>
        </div>
    );
}
export default Emailing;