import React from "react";

import serviceJson from '../../json_files/serviceComp.json';
import ServiceComp from "./serviceComponent";

export default function ServiceContainer(){
    return (
        <div>
            {
                serviceJson && serviceJson.map(
                    (serv)=>{
                        return(
                                <ServiceComp key={serv.title} title={serv.title} 
                                    containerClass={serv.containerClass}
                                    backgroundGradient={serv.backgroundGradient}
                                    image={serv.image} 
                                    titleColor={serv.titleColor}
                                    description1={serv.description1} 
                                    description2={serv.description2}
                                    direction={serv.direction}
                                />
                        )
                    }
                )
            }
        </div>
    )
}