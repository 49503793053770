import React from "react";
import './partnerbrand.css';
import { Fade, Bounce } from "react-awesome-reveal";

export default function PartnerBrand(){
    return (
        <div className="partner-container">
            <Bounce direction="down" triggerOnce="true">
                <h1>
                    You’ll be in good company
                </h1>
            </Bounce>
            <span className="partner-brand-subtitle">
                <Fade direction="up" triggerOnce="true">
                    We use an agile approach to test assumptions and connect with the needs of your audience early and often.
                </Fade>
            </span>

            <div className="brand-logos">
                <div className="brand-logo">
                    <img src="images/partner_brand/vishal.webp" alt="" className="brand-logo-icon"
                        style={{width:'60%', height:'60%', margin:'10px 0px 0px 0px', opacity:'0.9'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/msi.webp" alt="" className="brand-logo-icon"
                        style={{width:'60%', height:'60%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/acer.webp" alt="" className="brand-logo-icon"
                        style={{width:'50%', height:'50%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/benq.webp" alt="" className="brand-logo-icon"
                        style={{width:'38%', height:'32%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/amd.webp" alt="" className="brand-logo-icon"
                        style={{width:'38%', height:'30%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/hyperx.webp" alt="" className="brand-logo-icon"
                        style={{width:'60%', height:'60%', margin:'15px 0px 0px 20px'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/intel.webp" alt="" className="brand-logo-icon"
                        style={{width:'40%', height:'40%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/logitech.webp" alt="" className="brand-logo-icon"
                        style={{width:'37%', height:'35%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/pw.webp" alt="" className="brand-logo-icon"
                        style={{width:'25%', height:'40%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/cosmicbyte.webp" alt="" className="brand-logo-icon"
                        style={{width:'60%', height:'60%'}}
                    ></img>
                </div>
                <div className="brand-logo">
                    <img src="images/partner_brand/gigabyte.webp" alt="" className="brand-logo-icon"
                        style={{width:'40%', height:'40%'}}
                    ></img>
                </div>
            </div>
        </div>
    )
}
