import contactJSON from '../../json_files/contact.json'
function Address() {
    return (
        <div className="contact-info">
            <h3>Get in Touch</h3>
            {
                contactJSON && contactJSON.map((val) =>
                    <div key={val.mail}>
                        <p><i className="fa fa-map-marker"></i> {val.address}</p>
                        <p><i className="fa fa-envelope"></i> {val.mail}</p>
                        <p><i className="fa fa-phone"></i> {val.phone}</p>
                        {/* <div className="social" style={{ overflow: 'hidden' }}>
                            <a href={val.insta} target="_blank" rel="noreferrer">
                                <i title={val.i_title} className={val.i_icon}></i>
                            </a>
                            <a href={val.youtube} target="_blank" rel="noreferrer">
                                <i title={val.y_title} className={val.y_icon}></i>
                            </a>
                            <a href={val.discord} target="_blank" rel="noreferrer">
                                <i title={val.d_title} className={val.d_icon}></i>
                            </a>
                            <a href={val.rooter} target="_blank" rel="noreferrer">
                                <i title={val.r_title} className={val.r_icon}></i>
                            </a>
                            <a href={val.facebook} target="_blank">
                                <i title={val.f_title} className={val.f_icon}></i>
                            </a>
                            <a href={val.twitter} target="_blank">
                                <i title={val.t_title} className={val.t_icon}></i>
                            </a>
                        </div> */}
                    </div>
                )
            }

        </div>
    );
}
export default Address;