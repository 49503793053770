import React from 'react';
import './about_style.css';
// import imgs from './aboutComImage.json';
// import AboutComImageSlider1 from './aboutComImageSlider1'; 
// import AboutComImageSlider2 from './aboutComImageSlider2'; 
// import AboutComImageSlider3 from './aboutComImageSlider3'; 
// import AboutComImageSlider4 from './aboutComImageSlider4'; 
// import AboutComImageSlider5 from './aboutComImageSlider5'; 
// import AboutComImageSlider6 from './aboutComImageSlider6'; 

export default function AboutComImage(){
    return (
            <div className='imageComCard'>
                <div className='size1'>
            
                </div>
                <div className='size2'>
                    
                </div>
                <div className='size3'>
                    
                </div>
                <div className='size4'>
                    
                </div>
            </div>
    )
}