import React from 'react';
import './css_file/our_css.css'
import ContentBox from './contentBox.js';
import CardsTitle from './cardsTitle.js';
import services from '../../json_files/services.json';

class Services extends React.Component {
    render() {
        return (
            <div className="our_services">
                <div className="container " style={{ overflow: 'hidden' }}>
                    <div className="row">
                        <CardsTitle subHeading={services.subHeading} 
                                    heading={services.heading} />
                        {
                            services && services.servicesList.map(
                                (service)=>{
                                    return (
                                            <ContentBox key={service.title} title={service.title} icon={service.icon}
                                                description={service.description}
                                            />
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;