import React from 'react';
import logo from '../../img/logo.webp';
import { Link } from 'react-router-dom';
import './css_file/brand.css'
import Navbar from './navbar';
import { Fade } from "react-awesome-reveal";
// import Bounce from 'react-reveal/Bounce';

class Brand extends React.Component{
    render(){
        return(
            // <div className="brand">
                <Fade className='brand' direction='down' triggerOnce="true">
                <div className="brand_LeftRight">
                    <div className="row" style={{overflow: 'hidden'}}>
                        <div className="col-lg-12 col-md-4">
                            <div className="b-logo">
                                <Link to="/">
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Navbar/>
                </div>
                </Fade>
            //</div>
        );
    }
}
export default Brand;